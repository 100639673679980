<template>
  <Layout>
    <v-container>
      <v-row>
        <v-col>
          <v-data-table
            dark
            :loading="loading"
            :headers="headers"
            :items="usersList"
            :search="search"
          >

            <!-- slot Top table -->
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><h2><v-icon large class="mr-2">mdi-account-group</v-icon>USER MANAGER</h2></v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn class="mt-2" fab dark color="secondary" @click.stop="newUser">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
              <v-toolbar flat>
                <v-toolbar-items>
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                  />
                </v-toolbar-items>
              </v-toolbar>
            </template>

            <!-- template Active -->
            <template v-slot:item.active="{ item }">
              <v-layout justify-center>
                <v-switch color="success" v-model="item.active" @change="toogleActive(item)" />
              </v-layout>
            </template>

            <!-- columna accion -->
            <template v-slot:item.action="{ item }">
							<v-tooltip top>
								<span>Edit</span>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
                    <v-icon class="action_button" @click.stop="editUser(item)">
                      mdi-file-edit
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
							<v-tooltip top>
								<span>Remove</span>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on">
                    <v-icon class="action_button" @click="removeUser(item)" color="red">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <user-dialog />

  </Layout>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from "vuex";
import store from '@/store'
import UserDialog from '@/components/dialog/UserDialog.vue'
const header = {"Token": store.state.authModule.token}
const configuration = {headers: header}

export default {
  name: 'UserView',
  components: { UserDialog },
  data: ()=>({
    usersList: [],
    search: '',
    headers: [
      { text: 'ROLE', align: 'start', value: 'role'},
      { text: 'NAME', align: 'start', value: 'name'},
      { text: 'LAST NAME', align: 'start', value: 'lastName'},
      { text: 'COMPANY', align: 'start', value: 'company'},
      { text: 'CITY', align: 'start', value: 'city'},
      { text: 'ACTIVE', value: 'active'},
      {text: '', value: 'action', sortable: false},
    ],
    json_fields: {
      'ROLE': 'role',
      'NAME': 'name',
      'LAST NAME': 'lastName',
      'COMPANY': 'company',
      'DEPARTMENT': 'department',
      'EMAIL': 'email',
      'PHONE': 'phone',
      'ADDRESS': 'address',
      'CITY': 'city',
      'STATE': 'state',
      'ZIP': 'zip',
      'COUNTRY': 'country',

    },
    items: [],
    editedIndex: -1,
    editedItem: [{
      _id: '',
      role: '',
      name: '',
      lastName: '',
      company: '',
      department: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      password: '',
    }],
    loading: false

  }),
  mounted(){
    this.getData()
  },
  methods: {
    ...mapActions('userModule',['getUsers','deleteUser']),
    async getData(){
      this.loading = true
      try {
        const users = await this.getUsers()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    newUser(){
      this.$store.commit('userModule/SET_USER', [], { root: true } )
      this.$store.commit('setDialog', true, { root: true })
    },
    editUser(item){
      this.editedItem = Object.assign({}, item)
      this.$store.commit('userModule/SET_USER', this.editedItem, { root: true } )
      this.$store.commit('setDialog', true, { root: true })
    },
    async toogleActive(item){
      let me = this;
      await axios.put('user/toggle', {'_id': item._id, 'active': item.active},configuration)
          .then(
              function (response) {
                me.$store.commit('SET_ALERT_MESSAGE', {
                  show: true,
                  color: 'success',
                  message: 'Status Updated successfull',
                  timeout: 4000
                }, {root: true})
              })
          .catch(function (e) {
            console.log(e.message)
          })
    },
    async removeUser(item){
      await this.$confirm('Are you sure you want to delete this user?', {
        title: 'Warning',
        color: 'error',
        icon: 'mdi-hand',
        buttonTrueColor: 'primary',
      })
          .then(res => {
            if (res){
              const id = item._id
              this.deleteUser(id)
              const index = this.users.findIndex(item => item._id === id);
              this.users.splice(index, 1);
            }
          })
    }


  },
  computed: {
    //...mapState(['loading']),
    ...mapState('userModule',['users','user']),
    userId(){
      return this.$store.state.authModule.user._id
    }
  },
  watch: {
    'users': function () {
      this.usersList = this.users.filter( x => x._id != this.userId);
    }
  }

}
</script>

<style lang="scss" scoped>
.action_button {
  margin: 0px 10px 10px 0px;
  font-size: xx-large;
}

</style>