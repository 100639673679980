<template>
  <v-row justify="center">
    <v-dialog dark v-model="dialog" fullscreen persistent transition="dialog-bottom-transition" >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ formTitle }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :loading="saving" dark text @click.stop="validate()" > <v-icon class="mr-3" color="white">mdi-check-circle</v-icon> SAVE</v-btn>
            <v-btn dark text @click="closeDialog"> <v-icon color="white">mdi-close</v-icon> Cancelar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container class="pt-5">
            <v-form ref="form" v-model="valid">

              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                      v-model="editedItem.role"
                      :items="['ADMIN','STAFF']"
                      prepend-icon="mdi-focus-field"
                      menu-props="auto"
                      hide-details
                      label="Role"
                      :rules="[rules.required]"
                  />

                  <v-text-field
                      prepend-icon="mdi-account-reactivate-outline"
                      label="Name"
                      v-model="editedItem.name"
                      :rules="[rules.required]"
                  />
                  <v-text-field
                      prepend-icon="mdi-account-reactivate-outline"
                      label="Last name"
                      v-model="editedItem.lastName"
                      :rules="[rules.required]"
                  />
                  <v-text-field
                      prepend-icon="mdi-email-outline"
                      label="E-mail"
                      v-model="editedItem.email"
                      :rules="[rules.required, rules.email]"
                  />
                  <v-text-field
                      prepend-icon="mdi-office-building-outlinee"
                      label="Company"
                      v-model="editedItem.company"
                  />
                  <v-text-field
                      prepend-icon="mdi-cards-variant"
                      label="Departament"
                      v-model="editedItem.department"
                  />
                  <v-text-field
                      type="tel"
                      prepend-icon="mdi-phone"
                      label="Phone"
                      v-model="editedItem.phone"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="6">

                  <v-textarea
                      dense
                      outlined
                      prepend-icon="mdi-map-marker-radius-outline"
                      v-model="editedItem.address"
                      label="Street address"
                      :value="editedItem.address"
                      rows="2"
                      :rules="[rules.required]"
                  />
                  <v-text-field
                      prepend-icon="mdi-home-city-outline"
                      label="City"
                      v-model="editedItem.city"
                      :rules="[rules.required]"
                  />
                  <v-text-field
                      prepend-icon="mdi-city-variant-outline"
                      label="State"
                      v-model="editedItem.state"
                      :rules="[rules.required]"
                  />
                  <v-text-field
                      prepend-icon="mdi-flag-outline"
                      label="Country"
                      v-model="editedItem.country"
                      :rules="[rules.required]"
                  />
                  <div class="my-5">
                    <v-divider />
                  </div>
                  <v-text-field
                      type="password"
                      color="primary"
                      v-model="password"
                      :label="passwordLabel"
                      dense
                      outlined />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapState, mapActions} from 'vuex';
export default {
  name: 'UserDialog',
  data () {
    return {
      password: '',
      passwordLabel: '',
      saving: false,
      editedIndex: -1,
      valid: true,
      editedItem: {
        _id: '',
        role: '',
        name: '',
        lastName: '',
        company: '',
        department: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        password: '',
      },
      rules: {
        required: value => !!value || 'Item is required',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Must be valid e-mail'
        },
      },

    }
  },
  methods: {
    ...mapActions('userModule',['getUsers','addUser','updateUser']),
    closeDialog() {
      this.$store.commit('setDialog', false, { root: true })
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.saveUser()
      }
    },
    saveUser(){
      this.editedItem.email = this.editedItem.email.trim().toLowerCase()
      this.editedItem.password = this.password.trim()
      this.saving = true;
      if (this.user._id != null) {
        this.updateUser(this.editedItem)
      } else {
        this.addUser(this.editedItem)
      }
      this.getUsers()
      this.editedItem = {
        _id: '',
        role: '',
        name: '',
        lastName: '',
        company: '',
        department: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        password: '',
      }
      this.password = ''
      this.$refs.form.resetValidation()
      this.closeDialog()
      //setTimeout(() => { this.closeDialog() }, 2500);
      this.saving = false;
    },
  },
  computed: {
    ...mapState(['loading', 'dialog']),
    ...mapState('userModule',['users','user']),
    formTitle () {
      return this.user._id != null ? 'EDIT USER' : 'CREATE USER'
    },
  },
  watch: {
    'user': function() {
      if (this.user != null){ this.editedItem = this.user; }
    },
    'editedItem': function () {
      if (this.editedItem._id == ''){
        this.passwordLabel = "Password (Leave blank if you don't want change it)";
      } else {
        this.passwordLabel = "Password";
      }
    }
  }

}
</script>

<style lang="scss" scoped>
</style>